import React from 'react'

const Footer = () => {
  return (
    <>
      <div className='footer'>
        <div className='footer-box'>
          <div className='left' data-aos='fade-left'>
            <h4>Contactez-moi!</h4>
            <p>
              Vous pouvez me retrouver sur les plateformes sociales ci-dessous
              ou directement m'envoyer un mail à{' '}
              <a href='mailto:diamil.dev@gmail.com'>diamil.dev@gmail.com</a>
            </p>
            <div className='social-media'>
              <a
                href='https://www.linkedin.com/in/diamiltechdev/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-linkedin-in'></i>
              </a>
              <a
                href='https://github.com/Diam-98'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-github'></i>
              </a>
              {/* <a
                href="https://www.youtube.com/channel/UCQQ9kLuxj7DJELb3lqgQtdA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a> */}
            </div>
          </div>

          <div className='right' data-aos='fade-right'>
            <div className='footer-img'>
              <img
                src={process.env.PUBLIC_URL + '/my_profile.jpg'}
                alt='Mon Profile'
              />
            </div>
            <span className='name'>DIAM DIALLO</span>
          </div>
        </div>
      </div>
      <div className='bottom'>
        <p className='text-center'>
          &copy; 2022-2024, Diam Diallo. Tous droits réservés
        </p>
      </div>
    </>
  )
}

export default Footer
