import './App.css'
import Header from './header/Header'
import Skills from './skills/Skills'
import Services from './services/Services'
import Footer from './footer/Footer'
import ReactGA from 'react-ga'
import { useEffect } from 'react'
import Portfolio from './projects/Portfolio'
import PiwikPro from '@piwikpro/react-piwik-pro'
import 'aos/dist/aos.css'
import Aos from 'aos'

function App() {
  ReactGA.initialize('G-JNJD7SK9P6')
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  PiwikPro.initialize(
    'f0424790-5991-4a9b-a30f-79176a8d93b5',
    'https://diamiltechdev.containers.piwik.pro'
  )

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
    })

    // document.addEventListener('contextmenu', function (e) {
    // e.preventDefault()
    // })
  }, [])

  return (
    <div className='body'>
      {/* Header section */}
      <Header />

      {/* Sevices section */}
      <Services />

      {/* Projects section */}
      <Portfolio />

      {/* skill section */}
      <Skills />

      {/* Footer section */}
      <Footer />
    </div>
  )
}

export default App
