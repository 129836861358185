import React from 'react'
import { Avatar } from 'antd'

const Project = ({ name, description, photo, link, linksec, tags, techs }) => {
  return (
    <div className='item'>
      <h5>{name}</h5>
      <div className='img-box'>
        <img src={photo.asset.url} alt={name} />
      </div>

      <div className='item-body'>
        <h5>{name}</h5>
        <p>
          {description}

          {tags != null &&
            tags.map((tag, index) => (
              <span className='tech' key={index}>{`#${tag.nom} - `}</span>
            ))}
        </p>
        <div className='access'>
          <a href={link} target='_blank' rel='noopener noreferrer'>
            <i className='ri-eye-fill'></i>
          </a>
          {linksec && (
            <a href={linksec} target='_blank' rel='noopener noreferrer'>
              <i className='fab fa-github'></i>
            </a>
          )}
        </div>
      </div>

      <div className='tech-img'>
        <Avatar.Group
          maxPopoverTrigger='click'
          size='large'
          maxStyle={{
            color: '#f56a00',
            backgroundColor: '#fde3cf',
            cursor: 'pointer',
          }}
        >
          {techs.map((tech, index) => {
            return <Avatar src={tech.asset.url} key={index} />
          })}
        </Avatar.Group>
      </div>
    </div>
  )
}

export default Project
