import React, { useEffect, useState } from 'react'
import sanityClient from '../sanityClient'

const Skills = () => {
  const [title, setTitle] = useState('Les meilleurs Techs')
  const [stacks, setStacks] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [description, setDescription] = useState(
    'Les techs les plus recentes pour en faire de meilleurs creations. Cliquez sur les icons a gauche pour decouvrir les techs.'
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await sanityClient.fetch(
          `*[_type == "stack"] | order(rank asc) {
            name,
            technologies[]->{name, image {asset->{url}}, description}
        }`
        )
        setStacks(result)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  const handleContent = (item) => {
    setTitle(item.name)
    setDescription(item.description)
  }

  if (isLoading) {
    return <p>Chargement...</p>
  }

  return (
    <>
      <div className='container skill-section mt-5'>
        <div className='section-intro' data-aos='fade-up'>
          <div className='d-flex align-items-center'>
            <i className='ri-code-box-line'></i>
            <h5>Les technologies avec lesquelles je travaille</h5>
          </div>
          <p>
            Je travaille avec des technologies modernes, puissante et sécurisées
            pour vous fournir de meilleurs résultats et satisfaisant.
          </p>
        </div>

        <div className='my-skills'>
          <div className='skill-container' data-aos='fade-right'>
            <div className='skill-container-item'>
              {stacks.map((stack, index) => (
                <div key={index}>
                  <h3>{stack.name}</h3>
                  <div className='skill-image-container'>
                    {stack.technologies.map((tech, techIndex) => (
                      <div
                        className='skill-image'
                        key={techIndex}
                        onClick={() => handleContent(tech)}
                      >
                        <img
                          src={tech.image.asset.url}
                          alt={`icon ${techIndex}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='right-side' data-aos='fade-left'>
            <div className='left-box'>
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Skills
