import React, { useCallback } from 'react'
import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'

// import contact from '../images/contact-icon.png'
import linkedin from '../images/linkedin-icon.png'
import mail from '../images/mail-icon.png'

const Header = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine)
  }, [])

  const particlesLoaded = useCallback(async (container) => {}, [])

  return (
    <>
      <div className='header-main'>
        <Particles
          className='particules'
          id='tsparticles'
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            // background: {
            //   color: {
            //     value: "#0d47a1",
            //   },
            // },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: 'push',
                },
                onHover: {
                  enable: true,
                  mode: 'repulse',
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: '#ffffff',
              },
              links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: 'none',
                enable: true,
                outModes: {
                  default: 'out',
                },
                random: false,
                speed: 3,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 400,
                },
                value: 30,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'circle',
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
        <div className='top'>
          <div className='logo'>
            <h3>
              <span>Diamil</span>Dev
            </h3>
          </div>
          <div className='social-media'>
            <a
              href='https://www.linkedin.com/in/diamiltechdev/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fab fa-linkedin-in'></i>
            </a>
            <a
              href='https://github.com/Diam-98'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fab fa-github'></i>
            </a>
          </div>
        </div>
        <div className='hero-box'>
          <div className='hero-text' data-aos='slide-right'>
            <p>
              Salut, Je m'appelle <span className='name'>DIAM DIALLO</span>
            </p>
            <h2>
              Je suis Développeur full stack web et mobile | Formateur sur UDEMY
              (
              <a
                href='https://www.udemy.com/user/diam-diallo-2/'
                target='_blank'
                rel='noreferrer'
              >
                Mon Profile
              </a>
              )
            </h2>
            <h3>
              Je travaille avec les technologies comme : PHP, Java, Javascript &
              Dart(Flutter).
            </h3>
            <p>
              <span>Bienvenu,</span> c'est un grand plaisir de vous accueillir
              ici. Je développe des applications web réactives et robustes en
              utilisant{' '}
              <a
                href='https://laravel.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Laravel
              </a>
              ,{' '}
              <a
                href='https://symfony.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Symfony
              </a>{' '}
              et{' '}
              <a
                href='https://fr.reactjs.org/'
                target='_blank'
                rel='noopener noreferrer'
              >
                React
              </a>
              , mais également des applications mobiles{' '}
              <a
                href='https://developer.android.com/about?hl=fr'
                target='_blank'
                rel='noopener noreferrer'
              >
                Android
              </a>{' '}
              avec Java et des applications multiplateformes Android/IOS avec{' '}
              <a
                href='https://flutter.dev/'
                target='_blank'
                rel='noopener noreferrer'
              >
                flutter
              </a>
              .
            </p>
            <p>
              Je suis disponible pour un poste de développeur web et/ou mobile,
              ou des projets freelance. N'hésitez pas a me contactez a l'adresse{' '}
              <a href='mailto:diamil.dev@gmail.com'>diamil.dev@gmail.com</a>
            </p>

            <div className='button-box'>
              <a
                href='mailto:diamil.dev@gmail.com'
                className='contact-opt-button'
              >
                <img src={mail} alt='contacts' />
              </a>
              {/* <div className='contact-opt-button'>
                <img src={contact} alt='contacts' />
              </div> */}
              <a
                href='https://www.linkedin.com/in/diamiltechdev/'
                className='contact-opt-button'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={linkedin} alt='contacts' />
              </a>
            </div>
          </div>

          <div className='img-orbital-container'>
            <div className='hero-img' data-aos='zoom-in-right'>
              <img
                src={process.env.PUBLIC_URL + '/my_profile.jpg'}
                className='main-profile-img central-image'
                alt='Ma profile'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
