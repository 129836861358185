import React from "react";

const Services = () => {
  return (
    <>
      <div className="container service-section mt-5">
        <div className="section-intro" data-aos="fade-down">
          <div className="d-flex align-items-center">
            <i className="ri-service-line"></i>
            <h5>Les services que je peux rendre</h5>
          </div>
          <p>
            Je peux travailler avec vous dans la conception et la réalisation de
            vos projets web et/ou mobile à partir de zéro, ou travailler avec
            vous en tant que freelance.
          </p>
        </div>
        <div className="skill-boxes">
          <div className="item" data-aos="flip-down">
            <h4>1. Backend/API</h4>
            <p>
              Je développe des API REST robustes, évolutives et maintenables
              pour vos applications Web et/ou mobile avec des technologies
              modernes en suivant les meilleurs pratiques.
            </p>
          </div>

          <div className="item" data-aos="flip-down">
            <h4>2. Applications web</h4>
            <p>
              Je crée des applications web réactives et élégantes en utilisant
              des technologies modernes et populaires. L’expérience utilisateur
              est aussi importante que l’interface utilisateur, je m’assure donc
              que votre application web soit accessible.
            </p>
          </div>

          <div className="item" data-aos="flip-down">
            <h4>3. Applications mobile IOS/android</h4>
            <p>
              Je crée aussi des applications mobiles multiplateformes avec des
              technologies modernes et populaires. L’expérience utilisateur
              comme le web est aussi importante que l’interface utilisateur.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
