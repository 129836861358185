import React, { useEffect, useState } from 'react'
import Project from './Project'
import sanityClient from '../sanityClient'

const Portfolio = () => {
  const [projects, setProjects] = useState([])
  const [courses, setCourses] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await sanityClient.fetch(
          `*[_type == "projet"] | order(_createdAt desc){
            titre,
            photo {asset->{url}},
            description,
            lien1,
            lien2,
            tags,
            techs[] {asset->{url}}
        }`
        )

        const course = await sanityClient.fetch(
          `*[_type == "course"] | order(_createdAt desc){
            titre,
            photo {asset->{url}},
            description,
            lien1,
            techs[] {asset->{url}}
        }`
        )

        setProjects(result)
        setCourses(course)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  if (isLoading) {
    return <p>Chargement...</p>
  }

  return (
    <div className='container mt-5'>
      <div className='section-intro' data-aos='fade-down'>
        <div className='d-flex align-items-center'>
          <i className='ri-computer-fill'></i>
          <h5>Les projets sur lesquels j'ai travaillé</h5>
        </div>
        <p>
          J’ai eu à réaliser des projets web et mobile dont certains de la
          conception au déploiement, d’autres en cours de développement et
          d’autre sur mes repositories{' '}
          <a
            href='https://github.com/Diam-98?tab=repositories'
            target='_blank'
            rel='noopener noreferrer'
          >
            GitHub
          </a>
          . Je me lance aussi dans des challenges simples et complexes en créant
          de simples projets d'applications avec React JS, et Mobile.
        </p>
      </div>

      <div className='project-boxes' data-aos='zoom-in-up'>
        {projects.map((project, index) => (
          <Project
            key={index}
            name={project.titre}
            description={project.description}
            image={project.photo.asset.url}
            link={project.lien1}
            linksec={project.lien2}
            photo={project.photo}
            tags={project.tags}
            techs={project.techs}
          />
        ))}
      </div>

      <div className='section-intro' data-aos='fade-down'>
        <div className='d-flex align-items-center'>
          <i className='ri-computer-fill'></i>
          <h5>Les cours que j'ai publié sur UDEMY</h5>
        </div>
        <p>
          J’ai eu à créer des cours sur le developpement3 Web, API, FullStack
          avec Laravel et react js. Avec plus de 200 participants et une note
          global de 4.2
        </p>
      </div>

      <div className='project-boxes' data-aos='zoom-in-up'>
        {courses.map((course, index) => (
          <Project
            key={index}
            name={course.titre}
            description={course.description}
            image={course.photo.asset.url}
            link={course.lien1}
            photo={course.photo}
            techs={course.techs}
          />
        ))}
      </div>
    </div>
  )
}

export default Portfolio
